<template>
    <div class="px-4 py-1 stop">
      <div class="py-1 font-bold text-black border-b border-gray-300 title">
        <!-- {{ title }} -->
      </div>
      <a-modal width='550px'  okText="保存" :footer="null" cancelText="关闭" v-model="visiblem" title="套餐管理" >
        <p style="display:flex">
          <span style="width:20%;">套餐名称:</span><span>
            <a-input size="small" placeholder="请输入套餐名称" v-model="thali.title" />
          </span>
          <span style="width:20%;">&nbsp;&nbsp;&nbsp;体型总数:</span><span>
            <a-input type="number" size="small" placeholder="请输入体型总数" v-model="thali.total_size_num" />
          </span>
        </p>
        <p style="display:flex">
          <span  style="width:20%;">套餐价格:</span><span>
            <a-input type="number" size="small" placeholder="请输入套餐价格" v-model="thali.goods_activity_price" />
          </span>
          <span  style="width:20%;">&nbsp;&nbsp;&nbsp;单款体型数:</span><span>
            <a-input type="number" size="small" placeholder="请输入单款体型数" v-model="thali.order_min_size_num" />
          </span>
        </p>
        <p style="display:flex">
          <span  style="width:20%;">单价:</span><span>
            <a-input type="number" size="small" placeholder="请输入单价" v-model="thali.unit_price" />
          </span>
          <span  style="width:20%;">&nbsp;&nbsp;&nbsp;可购买次数:</span><span>
            
            <a-input type="number" size="small" placeholder="请输入可购买次数" v-model="thali.purchase_max_times" />
          </span>
        </p>


        <p style="display:flex">

          
        </p>
          
        <p style="display:flex">
          <span  style="width:120px;">套餐活动时间:</span><span>{{checkData.unified_social_credit_code}}</span>
          <span>       
             <div class="  input_box">

              <a-date-picker
              v-model="startValue"
              :disabled-date="disabledStartDate"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="Start"
              @openChange="handleStartOpenChange"
            />
            <a-date-picker
              v-model="endValue"
              :disabled-date="disabledEndDate"
              show-time
              format="YYYY-MM-DD HH:mm:ss"
              placeholder="End"
              :open="endOpen"
              @openChange="handleEndOpenChange"
            />

            <!-- <a-range-picker
              size="small"
              style="width: 250px;"
              :placeholder="['开始日期', '结束日期']"
              :value="showDateTime"
              @change="onChange"
            >
              <template slot="dateRender" slot-scope="current">
                <div
                  class="ant-calendar-date"
                  :style="getCurrentStyle(current)"
                >
                  {{ current.date() }}
                </div>
              </template>
            </a-range-picker> -->
          </div></span>
        </p>
        <p>
          <span  style="width:120px;">备注:&nbsp;&nbsp;&nbsp;&nbsp;</span><span>
            <a-textarea placeholder="请输入备注" v-model="thali.remark" />
          </span>
        </p>
        <div style="width:70%;margin:0 auto;height:30px;">
        <a-button style="float:left;" @click="oncancel()" type="primary" danger ghost>取消</a-button>
        <a-button style="float:right;" @click="handleOk(thali.id)" type="primary">确定</a-button>
        </div>
      </a-modal>
      <div class="pt-2 content_item">
        <div class=" items-center justify-between">
          <div style='width:100%' class=" items-center item">
      
            <div style="height:50px;"> 
            <a-button  style='float:right;width:64px;background-color:#294DEA;color:#fff' size="large" type="" @click="resetFunc" class="ml-2"
              >添加</a-button>
            
            </div> 
          </div>
        </div>
        <div style = 'background-color:#fff;margin-top:6px;' class="pt-4">
          <a-table
            :data-source="dataSource"
            :rowKey = "(record,index)=>{return index}"
            :columns="columns"
            :scroll="{ x: 1000 }"
            :pagination="pagination" 
          >
          <template style="" slot="setime" slot-scope="text, record">
            <!-- {{record.refund_status_text}}   -->
           
            <span>{{record.start_time}}</span>~<span>{{record.end_time}}</span>
              
            </template>
            <template style="" slot="operation" slot-scope="text, record">
              <!-- <div v-if="record.statusName!='已上传'" class="flex"> -->
                <div class="flex">
                  
                <a-button 
                  type="default"
                  class="mr-2"
                  @click="collectData(record.id)"
                  >编辑</a-button
                >
                <a-button
                v-if="record.status == 0"
                type="default"
                class="mr-2"
                @click="upDown(record.id,record.status)"
                >上架</a-button
              >
              <a-button
              v-if="record.status == 1"
              type="default"
              class="mr-2"
              @click="upDown(record.id,record.status)"
              >下架</a-button
            >
              </div>
  
            </template>
          </a-table>
        </div>
        <a-modal cancelText="关闭"   v-model:visible="visible" title="退款信息" >
        <p>{{refund_explain}}</p>
        <!-- <p>{{refund_status_text}}</p> -->
          <template slot = 'footer'>
            <a-button @click="handCancelgb">关闭</a-button>
          </template>
        </a-modal>

      </div>
     
    </div>
  </template>
  
  <script>
  import {thaliList, thaliInfo ,thaliAdd  ,thaliEdit ,thaliUpdownGoods} from "@/api/collect";
  import moment from 'moment';
  import { transformDate } from "@/utils/transformDate";
  
  export default {
    data() {
      return {
        startValue: null,
      endValue: null,
      endOpen: false,
        addeditif:'add',
        thali:{
          goods_activity_price:'',
          order_min_size_num:'',

        },
        checkData:{},
        visiblem:false,
        member_id:'',
        ordersn:'',
        status:'',
        pay_type:1,
        value1:'',
        value2:'',
        refund_explain:'',
        put_ordersn:'',
        merchant_title:'',
        good_name:'',
        code:'',
        checked: false,
        visiblech:false,
        refund_status_text:'',
        showtype:'',
        visible:false,
        title: "",
        passid:0,
        titleObj: {
          local: "本地申请数据分析",
          web: "网页申请数据分析",
          month: "本月申请数据分析",
          year: "本年申请数据分析",
        },
        orderson:'',
  
        timearr:[],
        type: 2,
        typeObj: {
          local: 3,
          web: 4,
          month: 5,
          year: 6,
        },
        order: "",
        dataSource: [

        ],
        columns: [
          {
            title: "ID",
            dataIndex: "id",
            // fixed: "left",
            width: 40,
          },
          { title: "套餐名称", dataIndex: "title", width: 110 },
        //   {
        //     title: "客户充值帐号",
        //     dataIndex: "dateTime",
        //     width: 200,
        //   },
          {
            title: "套餐价格",
            dataIndex: "goods_activity_price",
            width: 60,
          },
          {
            title: "单价",
            dataIndex: "unit_price",
            width: 60,
          },
          {
            title: "体型总数",
            dataIndex: "total_size_num",
            width: 70,
          },
          {
            title: "单款体型数",
            dataIndex: "order_min_size_num",
            width: 70,
          },
          {
            title: "款型总数",
            dataIndex: "max_order_num",
            width: 60,
          },
  
          {
            title: "套餐活动时间",
           
             
            width: 190,
            scopedSlots: { customRender: "setime" },
          },
          {
            title: "状态",
            dataIndex: "status_text",
            width: 60,
          },
          {
            title: "备注",
            dataIndex: "remark",
            width: 80,
          },
          {
            title: "操作",
          
             fixed: "right",
            width: 190,
            scopedSlots: { customRender: "operation" },
          },
        ],

        pagination: {
          // 关于页码的配置属性
          total: 0,
          pageSize:6,
          pageNo: 1,
          showSizeChanger: false, // 显示可改变每页数量
          pageSizeOptions: ["6"], // 每页数量选项
          showTotal: (total) => `共 ${total} 条`, // 显示总数
          onShowSizeChange: (current, pageSize) =>
            this.onSizeChange(current, pageSize), // 改变每页数量时更新显示
          onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
        },
        searchTime: [], // 查询的时间
        showDateTime: null, //默认给个null
      };
    },
    created() {
   
      this.aquireList();
    },
    watch: {

    },
    methods: {
      disabledStartDate(startValue) {
      const endValue = this.endValue;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.startValue;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
      handleChange(e){
        console.log(e)
        this.status = e
      },

      order_show(id,type){
        this.passid = id
        refundInfo({id:id}).then(res=>{
          if(res.code == 200){
            this.refund_explain = res.data.refund_explain
            this.refund_status_text = res.data.refund_status_text
          }
        })
        this.showtype = type
        if(type ==1){
          this.visiblech = true
        }
        if(type ==2 || type ==3){
          this.visible = true
          
        }
  
    
      },
      handCancelgb(){
  
        this.visible = false
      },
      handCancel(){
        this.visiblech = false
        refuseRefund({id:this.passid}).then(res=>{
          if(res.code ==200){
            this.$message.success("拒绝成功");
            location.reload()
          }else{
            this.$message.error("拒绝失败");
          }
        })
      },
      handleOk(idid){
        console.log(this.startValue)
        if(this.startValue==null || this.endValue==null){
          this.$message.error("请填写时间");
          return
        }
        this.visiblemv = false
        console.log(this.endValue)
        let passdata = {
    "title": this.thali.title,
    "goods_activity_price": this.thali.goods_activity_price,
    "unit_price": this.thali.unit_price,
    "total_size_num": this.thali.total_size_num,
    "order_min_size_num": this.thali.order_min_size_num,
    "start_time": this.startValue.format("YYYY-MM-DD hh:mm:ss"),
    "end_time": this.endValue.format("YYYY-MM-DD hh:mm:ss"),
    "purchase_max_times": this.thali.purchase_max_times,
    "remark": this.thali.remark
}
        if(this.addeditif=='add'){
         

console.log(passdata)

thaliAdd(passdata).then(res=>{
          if(res.code ==200){
            this.$message.success("添加成功");
            this.visiblem = false
            this.aquireList();
            // setTimeout(()=>{
            //   location.reload()
            // },1000)
          }else{
            this.$message.error(res.msg);
          }
        })
        }else{
          passdata.id = idid
          thaliEdit(passdata).then(res=>{
          if(res.code ==200){

            this.visiblem = false

            this.$message.success("修改成功");
            this.aquireList();
            // setTimeout(()=>{
            //   location.reload()
            // },1000)
          //  location.reload()
          }else{
            this.$message.error(res.msg);
          }
        })

        }

      },
      collectData(key) {
        this.thali.id = key
        this.addeditif ='edit'
        thaliInfo({id:key}).then(resa=>{
          if(resa.code ==200){
            let _obj = JSON.stringify(resa);
    let res = JSON.parse(_obj);
            
            this.visiblem = true
          
            this.$set(this.thali, 'title', res.data.title)
            this.$set(this.thali, 'goods_activity_price', parseFloat(res.data.goods_activity_price))
            this.$set(this.thali, 'total_size_num', parseFloat(res.data.total_size_num))
            this.$set(this.thali, 'unit_price', parseFloat(res.data.unit_price))
            this.$set(this.thali, 'order_min_size_num', parseFloat(res.data.order_min_size_num))
            this.$set(this.thali, 'purchase_max_times', parseFloat(res.data.purchase_max_times))
            this.$set(this.thali, 'remark', parseFloat(res.data.remark))

    this.startValue=  moment(res.data.start_time, 'YYYY-MM-DD HH:mm:ss');
    this.endValue= moment(res.data.end_time, 'YYYY-MM-DD HH:mm:ss');
          }
        })
        
        
  
      },
      showConfirm(){
        Modal.confirm({
          title: () => 'Do you want to delete these items?',
          icon: () => createVNode(ExclamationCircleOutlined),
          content: () => 'When clicked the OK button, this dialog will be closed after 1 second',
          onOk() {
            return new Promise((resolve, reject) => {
              setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            }).catch(() => console.log('Oops errors!'));
          },
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onCancel() {},
        });
      },
      oncancel() {
        this.visiblem = false
      },
      
      repateData(key){  
              let index = this.dataSource.findIndex((item) => {
          return item.key == key;
        });
        let data = this.dataSource[index];
        console.log(data.id)
        //this.showConfirm()            
                    repateStatus({order_id:data.id,action:'reshoot'}).then(res=>{
                       console.log(res)
                       console.log(key)
                       if(res.code == 200){
                       this.collectData(key,data.id,'reset')
                       }else{
                        alert(res.msg)
                       }
                    })
  
  
      },
  
  
      getCurrentStyle(current, today) {
        const style = {};
        if (current.date() === 1) {
          style.border = "1px solid #1890ff";
          style.borderRadius = "50%";
        }
        return style;
      },
  
      // 请求数据
      aquireList() {
        let data = {
          
          page: this.pagination.pageNo,
          limit: this.pagination.pageSize,
        //   ordersn :this.orderson,
        //   merchant_title :this.merchant_title,
        //   time:this.timearr
        };
        this.searchTime.length > 0 ? (data.time = this.searchTime) : "";
        thaliList()
          .then((res) => {
            if(res.code ==200){
              this.dataSource = [];
            this.dataSource.push(...res.data.list);
            this.pagination.total = res.data.count;
            }
      
          })
          .catch((error) => {
            console.log(error);
          });
      },
      change(e) {
        // console.log(e)
        this.page = e.current;
        this.aquireList();
      },
      // 分页里size改变触发的函数
      onSizeChange(_, pageSize) {
        this.pagination.pageNo = 1;
        this.pagination.pageSize = pageSize;
        this.aquireList();
      },
      // 页面改变时
      onPageChange(page, pageSize) {
        console.log(page, pageSize);
        this.pagination.pageNo = page;
        this.aquireList();
      },
      upDown(id,stats){
        let arr = []
        arr.push(id)
        let status = stats ==0 ? 1:0
        thaliUpdownGoods({ids:arr,status}).then(res=>{
          if(res.code == 200){
            this.$message.success("成功");
            this.aquireList();
          }
        })

      },
      // 跳转到采集页面（数据录入）

      resetFunc() {
        this.thali={}
        this.addeditif = 'add'
        this.visiblem= true
      },
      onChange(e) {
        this.showDateTime = e;
        let start = transformDate(e[0]);
        let end = transformDate(e[1]);
        this.searchTime = [];
        this.searchTime.push(start);
        this.searchTime.push(end);
        this.aquireList();
      },
    },
  };
  </script>
  
  <style lang="less" scoped>
  
  :global(.ant-select-selection__rendered){
    height: 24px !important;line-height: 24px !important;
  }
  :global(.ant-select-selection--single){
    height: 24px !important;line-height: 24px !important;
  }
  
  .ant-input-sm{height: 24px !important;}
  .ant-select-selection-single{height: 24px !important;line-height: 24px !important;}
  .title {
    font-size: 20px;
  }
  .input_box {
    width: 410px;
    margin-right: 0px;
  }
  
  .content_item_title {
    font-size: 16px;
    color: #000000;
  }
  .select_info {
    background: #afe8bc;
    .etc-gantanhao-copy-copy {
      font-size: 18px;
    }
    span {
      font-size: 14px;
    }
    i {
      font-style: normal;
      padding: 0px 2px;
      color: @primary-color;
    }
    .delete {
      color: red;
      user-select: none;
    }
  }
  </style>
  <style lang="less">
  .stop {
    .circle {
      display: inline-block;
      height: 6px;
      width: 6px;
      background: @primary-color;
      border-radius: 50%;
    }
    tr td:last-child {
   
      a:nth-child(1) {
        color: @primary-color;
      }
      a:nth-child(2) {
        color: red;
      }
    }
    th:last-child {
      text-align: right;
    }
  
    .ant-btn {
      display: flex;
      align-items: center;
    }
  }
  </style>
  